/*******************************
         Site Overrides
*******************************/
h5.ui.header {
  text-transform: uppercase;
  color: #36333C;
}

h2.ui.header.info {
  text-transform: uppercase;
  font-weight: 300;
}

