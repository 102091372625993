/*******************************
         Site Overrides
*******************************/

.ui.button {
  border: 1px solid #fff;
  text-transform: uppercase;
}

.ui.button.primary, .ui.button.secondary {
  border: 1px solid @backgroundColor;
}
