/*******************************
    User Variable Overrides
*******************************/

.ui.cards > .card .meta .date, .ui.card .meta .date {
    color: rgba(255, 255, 255, 0.5);
}

.ui.card.inventory-card {
    width: 15rem;
    min-width: 15rem;
    margin: 0;
    margin-right: 2rem;
}

.ui.card.inventory-card img {
    height: 12rem;
    object-fit: cover;
}

@media only screen and (max-width: 767px) {
    .ui.card.inventory-card {
        width: 10rem;
        min-width: 10rem;
    }
}
