/*******************************
         Site Overrides
*******************************/
.ui.container p.paragraph1 {
    font-size: 1.6rem;
    font-weight: 300;
}

.ui.container.horizontal-flex-scrollable {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    margin: 0 0;
}

.ui.container.navbar-mobile-menu-container {
    position: fixed;
    margin: 0 !important;
    z-index: 20;
    width: 100% !important;
    background-color: #000;
}

@media only screen and (max-width: 767px) {
    .ui.container.horizontal-flex-scrollable {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}
