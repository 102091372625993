/*******************************
         Site Overrides
*******************************/

.ui.secondary.inverted.pointing.menu .active.item {
  border-color: @pageBackground;
}

.ui.menu .item img {
   width: 1.6rem !important;
}

.ui.menu.navbar-mobile-menu {
   margin: 0 !important;
}

.ui.menu .item {
   padding: 1.8rem 1.6rem !important;
}
